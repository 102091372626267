import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import './scss/header.scss'
import FacebookIcon from '@material-ui/icons/Facebook'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const Header = ({ siteTitle }) => (
  <div className='navbar'>
    <List className='menu-list'>
      <ListItem>
        <ListItemText>
          <Link to="/" id='brand' style={{ color: '#fff' }}>
            Beyond One
      </Link>
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <Link to='/'>Home</Link>
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <Link to='greeting'>挨拶 / Konnichiwa</Link>
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <Link to='mission'>理念 / Mission</Link>
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <Link to='about-us'>会社概要 / About Us</Link>
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <Link to='contact'>お問い合わせ / Contact Form</Link>
        </ListItemText>
      </ListItem>
      <ListItem>
        <a href='https://facebook.com' style={{ marginTop: '1.2em' }}><FacebookIcon /></a>
      </ListItem>
    </List>
  </div >
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
