import React from "react"
import { Link } from 'gatsby'
import PropTypes from "prop-types"
import './scss/layout.scss'
import Nav from '../components/Nav'

const Layout = ({ children }) => {


  return (
    <>
      <Nav />
      <div className='wrapper'>
        <header id='brand' className='align-center'>Beyond One</header>
        <main style={{ marginBottom: '40px' }}>{children}</main>
      </div>
      <footer>
        © {new Date().getFullYear()}株式会社
          {` `}
        <Link to='/' style={{ marginLeft: '15px' }}>Beyond One</Link>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
