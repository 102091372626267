import openToggle from '../svgs/toggler.svg'
import closeToggle from '../svgs/closeToggle.svg'
import React, { useState } from 'react'
import Header from '../components/header'

const Nav = () => {

    const [isShown, setIsShown] = useState(false)

    const handleToggleButtonClick = () => {
        setIsShown(true)
    }

    const handleCloseButtonClick = () => {
        setIsShown(false)
    }



    return (
        <div>
            {isShown ? <Header />
                : ''}
            <button className='toggler' onClick={handleToggleButtonClick}>
                {isShown ? '' : <img src={openToggle} alt='toggle' />}
            </button>

            <button className='toggler' onClick={handleCloseButtonClick}>
                {isShown ?
                    <img src={closeToggle} alt='toggle' />
                    : ''}
            </button>
        </div>
    )
}

export default Nav